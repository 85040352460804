@import '~@angular/material/theming';

// Be sure that you only ever include 'mat-core' mixin once!
// it should not be included for each theme.
@include mat-core(); 

// define a real custom palette (using http://mcg.mbitson.com)
$md-wipprimary: (
    50 : #fde0e3,
    100 : #f9b3b9,
    200 : #f6808b,
    300 : #f24d5c,
    400 : #ef2639,
    500 : #ec0016,
    600 : #ea0013,
    700 : #e70010,
    800 : #e4000c,
    900 : #df0006,
    A100 : #7f7f7f,
    A200 : #d0d0d0,
    A400 : #ffc9c9,
    A700 : #ff8687,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-wipaccent: (
    50 : #f0f0f0,
    100 : #d9d9d9,
    200 : #bfbfbf,
    300 : #a5a5a5,
    400 : #929292,
    500 : #7f7f7f,
    600 : #777777,
    700 : #6c6c6c,
    800 : #626262,
    900 : #4f4f4f,
    A100 : #f8bfbf,
    A200 : #f39191,
    A400 : #ff5252,
    A700 : #ff3838,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #ffffff,
    )
);

// mandatory stuff for theming
$bv-palette-primary: mat-palette($md-wipprimary);
$bv-palette-accent:  mat-palette($md-wipaccent);

// include the custom theme components into a theme object
$bv-theme: mat-light-theme($bv-palette-primary, $bv-palette-accent);

// include the custom theme object into the angular material theme
@include angular-material-theme($bv-theme);


.mat-expansion-panel, .mat-paginator, .mat-card {
    background-color: #7f7f7f;
    color: lightgray;
}

.mat-expansion-panel .mat-panel-description span {
    color: lightgray;
}

.mat-paginator {
    .mat-select-value {
        color: lightgray !important;
    }
}

.mat-expansion-panel {
    mat-form-field *:not(mat-label) {
        color: white;
    }
    .mat-select-value {
        color: lightgray !important;
    }
}

.mat-expansion-panel-header-title {
    color: lightgray
}
.mat-expansion-panel-header-description {
    color: lightgray
}

.mat-row .mat-cell span:not(.material-icons), .mat-row .mat-cell input {
    color: black !important;
}

.mat-row .mat-cell input {
    width: 100px !important;
}

.mat-cell {
    padding: 3px !important;
}

.mat-row .mat-cell input {
    min-width: 130px;
}

.mat-header-row * {
    color: black !important;
    font-weight: bold;
}

td:not(.content) {
    white-space: nowrap;
}

.mat-tooltip {
    font-size: small !important;
    background: rgb(236, 0, 22) !important;
    border: 3px solid lightgray !important;
    white-space: pre-line !important;
}

.btn-icon {
    color: black !important;
    font-size: x-large !important;
    cursor: pointer !important;
    line-height: 140% !important;
}

.btn-icon:hover {
    color:rgb(236, 0, 22) !important;
}

.mat-standard-chip {    
    height: auto !important;
  }

  .mat-raised-button:disabled {
      background-color: grey !important;
  }

/*.mat-row .mat-cell .btn-icon {
    color: black !important;
    font-size: x-large !important;
    cursor: pointer !important;
    line-height: 140% !important;
}

.mat-row .btn-icon:hover {
    color: red !important;
}*/


